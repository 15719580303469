.download-section {
  padding: 10px;
}
.download-xlsx {
  float: right;
  background-color: Crimson;
  border-radius: 5px;
  color: white;
  padding: 0.5em;
  text-decoration: none;
}
.download-xlsx:hover {
  background-color: Crimson;
  color: #fff;
}

.EditBox {
  position: relative;
}

.progressInfo {
  position: absolute;
  width: 250px;
  bottom: 40px;
  left: -80px;
  background-color: #d9d9d9;
  border-radius: 10px;
  /* transform: translate(-40px); */
  display: none;
  transition: display 0.5s ease;
}
.infoText {
  padding: 20px;
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.EditBox:hover .progressInfo {
  display: flex;
}
