@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inder&family=Inter:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Orbitron&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inder&family=Inter:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&family=Noto+Sans+Arabic:wght@400;500;600;700&display=swap);
body {
  font-family: "Inter" sans-serif;
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: rgb(0, 95, 135);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: rgb(0, 70, 105);
}
.fileUpload-input {
  content: "Select some files";
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 12px 6px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
.fileUpload-input::-webkit-file-upload-button {
  visibility: hidden;
}
.fileUpload-input:hover::before {
  border-color: black;
}
.fileUpload-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.questionDetailsImageContainer {
  width: 100%;
  height: 300px;
  padding: 30px 150px;
}
.image-tag {
  width: auto;
  height: 100%;
  object-fit: contain;
  max-width: 500px;
  z-index: 999999999 !important;
}

@media (max-width: 600px) {
  .image-tag {
    width: 200px;
    height: 100%;
    object-fit: contain;
    z-index: 999999999 !important;
  }
}

.space-btw {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.login-btn {
  background: linear-gradient(
      0deg,
      rgba(217, 217, 217, 0),
      rgba(217, 217, 217, 0)
    ),
    linear-gradient(0deg, #f1f1f1, #f1f1f1);
  border: 1px solid #f1f1f1 !important;
  border-radius: 45px !important;
  box-shadow: none !important;
  padding: 10px 40px;
  margin-bottom: 20px;
}
.login-btn:hover {
  background: linear-gradient(
      0deg,
      rgba(217, 217, 217, 0),
      rgba(217, 217, 217, 0)
    ),
    linear-gradient(0deg, #f1f1f1, #f1f1f1);
  border: 1px solid #f1f1f1 !important;
  border-radius: 45px !important;
  box-shadow: none !important;
  padding: 10px 40px;
  margin-bottom: 20px;
  color: white;
}

.register-btn {
  background: linear-gradient(
      0deg,
      rgba(217, 217, 217, 0),
      rgba(217, 217, 217, 0)
    ),
    linear-gradient(0deg, #f1f1f1, #f1f1f1);
  border-radius: 45px !important;
  box-shadow: none !important;
  padding: 10px 40px;
  margin-bottom: 20px;
}
.register-btn:hover {
  background: linear-gradient(
      0deg,
      rgba(217, 217, 217, 0),
      rgba(217, 217, 217, 0)
    ),
    linear-gradient(0deg, #f1f1f1, #f1f1f1);
  border: 1px solid #f1f1f1 !important;
  border-radius: 45px !important;
  box-shadow: none !important;
  padding: 10px 40px;
  margin-bottom: 20px;
  color: white;
}
.login-log {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  z-index: 1999;
  margin-top: -70px;
}
@media (max-width: 300px) {
  .login-log {
    margin-top: -30px;
  }
}

.ant-tabs-ink-bar {
  background: none;
}

.parallax {
  /* background-image: url("./homepage.jpg"); */
  height: 100vh;
  /* background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.home-page-container {
  width: 100%;
  background-image: url(/static/media/login_page_bg.79b8307e.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.login-student-container {
  width: 550px;
  height: 450px;
  /* background-image: linear-gradient(
    to right bottom,
    rgb(183, 233, 247),
    rgb(219, 243, 250),
    rgb(245, 252, 255)
  ); */
  border-radius: 5px;
  /* border: 1px solid #ccc;
  box-shadow: 0 0 10px #ccc; */
  /* position: absolute;
  top: 50%;
  bottom: 50%; */
  /* transform: translateY(-35%);
  float: right;
  right: 50px; */
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
.login-container {
  width: 500px;
  /* height: 450px; */
  /* background-image: linear-gradient(
    to right bottom,
    rgb(183, 233, 247),
    rgb(219, 243, 250),
    rgb(245, 252, 255)
  ); */

  border-radius: 5px;
  /* border: 1px solid #ccc;
  box-shadow: 0 0 10px #ccc; */
  /* position: absolute;
  top: 50%;
  bottom: 50%; */
  /* transform: translateY(-35%);
  float: right;
  right: 50px; */
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
.login-inner {
  padding: 50px 30px;
}
.login-student-inner {
  padding: 30px;
  background: linear-gradient(180deg, #3b4046 0%, #7a7a7a 100%);
  box-shadow: 4px 2px 4px 0px #00000040;
  border-radius: 10px;
}
.login-inner form {
  padding: 0px 50px;
}
.login-inner h1 {
  text-align: center;
  font-weight: 600;
  font-size: 2rem;
}
.login-inner input {
  background-color: #dbdbdb;
  border: 1px solid brown;
  height: 2.5rem;
  border-radius: 8px;
}

.login-inner input::-webkit-input-placeholder {
  color: #714141;
  font-weight: 400;
}
.login-inner button {
  height: 2.7rem;
  border-radius: 8px;
  border: 0;
  font-weight: 600;
  background-color: #267693;
  box-shadow: 0 10px 6px -6px #7a9ba8;
}

.login-inner button:hover {
  background-color: #4790ab;
}

.lable {
  color: aliceblue;
  font-size: 15px;
}

.register_login_switch {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 2rem;
}

.space-btw-registor {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 600px) {
  .space-btw-registor {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

.register_login_switch span {
  padding: 3px 20px;
  font-weight: 600;
  transition: all 0.2s ease-in;
  border: 1px solid rgb(101, 86, 86);
  cursor: pointer;
}

.selected {
  background-color: #267693 !important;
  color: white !important;
  cursor: default !important;
}

.register_login_switch span:nth-child(1) {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-right: 0;
}

/* .register_login_switch span:nth-child(2) {
  border-left: 0;
} */
.register_login_switch span:nth-child(2) {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-left: 0;
}
.login-footer {
  padding-top: 10px;
  float: right;
}
.ant-form-item-label {
  line-height: 24px;
}
.ant-form-item {
  margin-bottom: 15px;
}

.login-student-inner form {
  padding: 0px 50px;
}

@media (max-width: 600px) {
  .login-student-inner form {
    padding: 0 !important;
  }
}
.login-student-inner h1 {
  text-align: center;
  font-weight: 600;
  font-size: 2rem;
}
.login-student-inner input {
  background-color: #dbdbdb;
  border: 1px solid #f1f1f1;
  height: 3rem;
  /* border-radius: 8px; */
}

.login-student-inner .ant-select-selection {
  background-color: #dbdbdb;
  border: 1px solid #f1f1f1;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-radius: 8px; */
}
.ant-select-selection__placeholder .ant-select-selection-selected-value {
  height: 100%;
  object-fit: 100%;
  width: 100%;
  color: #3b4046;
}
.login-student-inner label {
  color: #f1f1f1;
}

.login-student-inner a {
  color: #f1f1f1;
  text-decoration: underline;
}

.login-student-inner input::-webkit-input-placeholder {
  color: #714141;
  font-weight: 400;
}
.login-student-inner button {
  height: 3rem;
  border-radius: 10px;
  border: 0;
  font-weight: 600;
  background-color: #0082fb;
  /* -webkit-box-shadow: 0 10px 6px -6px #86b4c5;
  -moz-box-shadow: 0 10px 6px -6px #7ba8b8;
  box-shadow: 0 10px 6px -6px #7a9ba8; */
}

.login-student-inner button:hover {
  background-color: #0082fbe9;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

.logo11 {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 24px 16px 0;
  float: left;
  z-index: 10;
}

.d-logo {
  height: 55px;
  margin-top: -17px;
}
.user-options-list {
  position: fixed;
  top: 0px;
  right: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  list-style: none;
  float: left;
}
.user-options-list > * {
  display: inline;
  margin-right: 15px;
}

.sideOpenIcon {
  position: absolute;
  left: 2%;
  color: white;
}

@media (min-width: 0px) and (max-width: 769px) {
  .sider-wrapper {
    width: 0% !important;
    display: none;
  }
}

@media (min-width: 860px) {
  .siderToggle {
    display: none;
  }
}

.admin-table-container {
  width: 100%;
  padding: 20px;
  min-height: 80vh;
}

.register-trainer-form {
  width: 100%;
  border-radius: 5px;
}
.register-trainer-form-header {
  width: 100%;
  height: 40px;
  background-color: rgb(120, 135, 145);
  border-radius: 5px 5px 0px 0px;
  padding-top: 10px;
}
.register-trainer-form-body {
  width: 100%;
}
.input-admin-trainer {
  width: 90%;
  margin-left: 5%;
}

.admin-table-container {
  width: 100%;
  padding: 20px;
  min-height: 80vh;
}

.ansInput {
  box-shadow: 0px 4px 4px 0px #00000040 inset;
}

.tableHeadTextInvoice {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #5e5e5e;
}




#listHTML {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}
#listHTML img {
  height: 100px !important;
  width: 100px !important;
  margin-left: 40px !important;
}


.questionDetailsBody {
  padding: 30px;
}
.mainQuestionDetailsContaine {
  width: 100%;
}

.questionDetailsImage {
  /* max-width: 100%; */
  max-height: 200px;
}
.QuestionDetailsOptions {
  width: 100%;
  margin-bottom: 40px;
}



.download-section {
  padding: 10px;
}
.download-xlsx {
  float: right;
  background-color: Crimson;
  border-radius: 5px;
  color: white;
  padding: 0.5em;
  text-decoration: none;
}
.download-xlsx:hover {
  background-color: Crimson;
  color: #fff;
}

.EditBox {
  position: relative;
}

.progressInfo {
  position: absolute;
  width: 250px;
  bottom: 40px;
  left: -80px;
  background-color: #d9d9d9;
  border-radius: 10px;
  /* transform: translate(-40px); */
  display: none;
  transition: display 0.5s ease;
}
.infoText {
  padding: 20px;
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.EditBox:hover .progressInfo {
  display: flex;
}

.conduct-test-main-wrapper {
  min-height: 80vh;
}
.test-conduct-testid-form {
  width: 30%;
  margin-left: 35%;
  padding: 50px;
  border-radius: 5px;
  background: linear-gradient(
    to right,
    rgb(183, 233, 247),
    rgb(219, 243, 250),
    rgb(245, 252, 255)
  );
  text-align: center;
}
.candidate-list-header-container {
  padding: 0px 0px;
}
.reload-button {
  margin-bottom: 20px;
}
.green {
  background: rgb(16, 143, 16);
  color: #fff;
}

.profile_wrapper {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #3b4046 0%, #7a7a7a 100%) !important;
}

.profile_container {
  border: 2px solid #f1f1f1;
  width: 95%;
  margin: auto;
  height: 90vh;
  border-radius: 50px;
  overflow: auto;
}

.space-between {
  padding: 0% 1%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* .tabs {
  display: flex;
  justify-content: center;
  align-items: center;
} */
.container_inner {
  width: 95%;
  margin: auto;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}
.profile_text {
  font-family: "Inter", sans-serif;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 800;
  line-height: 48px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #f1f1f1;
}

.tabs .ant-tabs-bar {
  background-color: transparent;
}

.tabs .ant-tabs-nav {
  margin-bottom: 0; /* Remove the bottom margin of the tab navigation */
}
.tabs .ant-tabs-nav .ant-tabs-tab {
  margin: 0 !important;
  margin-left: -2px !important;
}
.tabs .ant-tabs-tab {
  color: #f1f1f1; /* Set the color of the tab text */
  padding: 10px 40px !important;

  border-radius: 0px 0px 25px 25px;
  position: relative; /* Set position relative to create a context for the divider */

  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
}

.tabs .ant-tabs-tab-active,
.tabs .ant-tabs-tab-active:focus {
  background-color: #f1f1f1;
  color: #4a525c !important;
  padding: 10px 40px !important;
}

.tabs
  .ant-tabs-tab:not(.ant-tabs-tab-active):not(
    .ant-tabs-tab-active:focus
  ):hover {
  color: #f1f1f1 !important;
}

/* padding: 10px 40px !important; */

.tabs
  .ant-tabs-tab:not(:last-child):not(.ant-tabs-tab-active):not(
    .ant-tabs-tab-active:focus
  )::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0px;
  height: 20%;
  width: 1px;
  background-color: #f1f1f1;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.tabs .ant-tabs-nav {
  border-top: 3px solid #f1f1f1;
}

.ant-tabs-ink-bar {
  background-color: transparent;
}
.modalTitle {
  font-family: "Inter", sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: center;
}
.modalButtons {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
}
.modalButtonYes {
  padding: 20px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-family: Inder; */
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  border: 1px solid #535353;
  cursor: pointer;
}
.modalButtonNo {
  padding: 20px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-family: Inder; */
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  background: #0082fb;
  color: white;
  cursor: pointer;
}
.ant-modal-content {
  border-radius: 20px;
}

@media (max-width: 600px) {
  .modalButtonNo {
    font-size: 12px;
  }
}

@media (min-width: 1700px) {
  .ant-tabs-tab {
    font-size: 30px;
  }
  .profile_text {
    font-size: 40px;
  }
}
@media (min-width: 769px) and (max-width: 1200px) {
  .ant-tabs-tab {
    font-size: 15px !important;
  }
  .profile_text {
    font-size: 22px !important;
  }
}

@media (min-width: 300px) and (max-width: 769px) {
  .ant-tabs-tab {
    font-size: 12px !important;
  }
  .profile_text {
    font-size: 18px !important;
  }
}
@media (max-width: 300px) {
  .profile_text {
    font-size: 14px !important;
  }
}

@media (max-width: 600px) {
  .tableDataText {
    font-size: 12px !important;
  }
}

.custom_trainee_table {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  padding: 30px 0px;
}
.sorting_Buttons {
  display: flex;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
  flex-wrap: wrap;
}
.sortButton {
  width: 150px;
  background-color: #0082fb;
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: space-between;
}
.icons {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.showData {
  position: absolute;
  width: 100%;
  height: 200px;
  overflow-y: scroll;
  top: 0;
  color: white;
  left: 0;
  z-index: 20;
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
  background-color: #938e8e;
  border-radius: 20px;
  padding: 12px;
}
.sortText {
  color: white;
}

.even_row {
  background-color: transparent !important;
  color: white !important;
  padding: 2px !important;
}

.odd_row {
  background-color: transparent !important;
  color: white !important;
  padding: 2px !important;
}

.even_row:hover,
.odd_row:hover {
  background-color: transparent !important;
}
.ant-table-tbody > tr.even_row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background-color: transparent !important;
}

.ant-table-tbody > tr.odd_row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background-color: transparent !important;
}

.custom_header {
  background-color: transparent !important;
  color: white !important;
}
.custom_tables .ant-table-thead > tr > th:last-child {
  border-bottom: none;
}
.custom_tables .ant-table-tbody > tr > td:last-child {
  border-bottom: none;
}

.custom_tables .ant-table-thead > tr > th {
  padding: 8px;
}
.custom_tables .ant-table-tbody > tr > td {
  padding: 8px;
}
.custom_tables .ant-table-placeholder {
  background-color: transparent;
}

@media (min-width: 0px) and (max-width: 769px) {
  .ant-table-wrapper {
    width: 100% !important;
  }
}

.custom_tables .ant-spin-container {
  position: relative;
  height: 100%;
}

.custom_tables .ant-table-pagination.ant-pagination {
  display: none;
}

@media (min-width: 1600px) {
  .resposiveFonts {
    font-family: "Inter" sans-serif;
    font-size: 20px !important;
    font-weight: 600;
    text-align: left;
  }
}

@media (max-width: 1020px) {
  .resposiveFonts {
    font-family: "Inter" sans-serif;
    font-size: 10px !important;
    font-weight: 500;
    text-align: left;
  }
}

.resposiveFonts {
  font-family: "Inter" sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}

.headerParrentWrapper {
  display: flex;

  grid-gap: 12px;

  gap: 12px;
  /* width: 95%;
  margin: auto; */
  padding: 10px 0px;
  align-items: flex-end;
}
.headerLeft {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
  width: 10%;
}
.headerInner {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
  width: 80%;
  font-family: "Inter", sans-serif;
}
.headerRight {
  display: flex;
  justify-content: center;
  grid-gap: 4px;
  gap: 4px;
  width: 10%;
}
.hederTestName {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 39px;
  letter-spacing: 0.05em;
  text-align: center;
  color: black;
}
@media (max-width: 600px) {
  .hederTestName {
    font-size: 16px;
  }
  .testHeaderTimeText {
    font-size: 16px !important;
  }
  .headerInner {
    width: 40%;
  }
  .headerParrentWrapper {
    display: flex;
    justify-content: space-evenly;
  }
}
.testHeaderButton {
  background: #0082fb;
  height: 40px !important;
  font-family: "Inder", sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
}
@media (max-width: 600px) {
  .testHeaderButton {
    width: 20px;
  }
}

.testHeaderButtonFinish {
  background: #0082fb;
  height: 40px !important;
  font-family: "Inder", sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 140px;
}

@media (max-width: 600px) {
  .testHeaderButtonFinish {
    width: 100%;
    min-width: 80px;
  }
}

@media (min-width: 1720px) {
  .testHeaderButton {
    background: #0082fb;
    height: 61px !important;
    font-family: "Inder", sans-serif;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: center;
    width: 190px;
  }
  .testHeaderButtonFinish {
    background: #0082fb;
    height: 61px !important;
    font-family: "Inder", sans-serif;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: center;
    width: 190px;
  }
  .exit {
    font-size: 20px;
  }
}

@media (max-width: 1020px) {
  .testHeaderButton {
    height: 30px !important;
    font-size: 14px;
    font-family: "Inder", sans-serif;
    width: 80px;
  }
  .testHeaderButtonFinish {
    height: 30px !important;
    font-size: 14px;
    font-family: "Inder", sans-serif;
    width: 80px;
  }
  .exit {
    font-size: 14px !important;
  }
}

.testHeaderTimeText {
  font-family: "Inter", sans-serif !important;
  font-size: 18px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
  color: black;
}
.testHeaderDivider {
  margin: 0px !important;
  border: 1px solid #1e1e1e;
}
.exit {
  background-color: transparent;
  outline: none;
  text-decoration: underline;
  color: #0082fb;
  border: none;
  font-family: "Inter" sans-serif !important;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  display: inline-block;
  white-space: nowrap;
}

.skeletor-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 100px;
}
.Test-portal-not-started-yet-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgb(205, 217, 225);
}
.Test-portal-not-started-yet-inner {
  width: 50%;
  min-height: 200px;
  background: linear-gradient(
    to right,
    rgb(183, 233, 247),
    rgb(219, 243, 250),
    rgb(245, 252, 255)
  );
  margin-left: 25%;
  position: absolute;
  top: 50%;
  bottom: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-radius: 5px;
  padding: 50px;
  vertical-align: middle;
}
.Test-portal-not-started-yet-inner-message {
  color: #fff;
  text-align: center;
}
.instaruction-page-wrapper {
  width: 100%;
  background: rgb(205, 217, 225);
  min-height: 100vh;
  padding: 50px 5%;
}
.instruction-page-inner {
  width: 100%;
  border: 3px solid rgb(0, 153, 203);
  border-radius: 5px;
  padding: 10px;
}
.proceed-to-test-button {
  width: 100%;
  padding: 20px 0px 40px 0px;
}
.exam-dashboard-wrapper {
  width: 100%;
  /* background: rgb(205, 217, 225); */
  position: absolute;
  /* height: 100vh; */
  min-height: 100%;
}
.exam-dashboard-wrapper-new {
  background: linear-gradient(180deg, #ffffff 0%, #f1f1f1 100%);

  width: 100%;
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  grid-gap: 8px;
  gap: 8px;
}
.ansInput {
  box-shadow: 0px 4px 4px 0px #00000040 inset;
}
/* .ansInputp {
  width: 380px;
}
@media (max-width: 768px) {
  .ansInputp {
    width: 200px;
  }
} */
.ansInputp .ant-input:focus {
  box-shadow: 0px 4px 4px 0px #00000040 inset;
  border-color: #ffff !important;
}
.ansInputp .ant-input:active {
  box-shadow: 0px 4px 4px 0px #00000040 inset;
  border-color: #ffff !important;
}
.ansInputp .ant-input:hover {
  box-shadow: 0px 4px 4px 0px #00000040 inset;
  border-color: #fff !important;
}
.ImagebgTest {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 0 !important;
}
.explanationText {
  position: absolute;
  z-index: 200000000;
  left: 50%;
  top: 1%;
  -webkit-transform: translate(-50%, -1%);
          transform: translate(-50%, -1%);
  cursor: pointer;
}
.editButton {
  position: absolute;
  z-index: 20;
  left: 50%;
  top: 1%;
  -webkit-transform: translate(-50%, -1%);
          transform: translate(-50%, -1%);
  cursor: pointer;
}
.editBox {
  background-color: #0082fb;
  color: white;
  padding: 8px 18px;
  width: 100px;
  display: flex;
  justify-content: center;
  border-radius: 20px;
}
.correct-answere {
  color: green;
  font-size: 22px;
}
.your-answere {
  color: red;
  font-size: 22px;
}
.MarkingModalBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
  background-color: #d9d9d9;
}

.markingTitle {
  font-family: Inter;
  font-size: 28px;
  font-weight: 300;
  text-align: center;
  color: #000000;
}
.markingPara {
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}
.textToggele {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
}

.side-panel-in-exam-dashboard {
  /*background: rgb(65,170,200);*/
  /* background: #009999; */
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  position: fixed;
  right: 0;
  height: 100%;
}
.w-20 {
  width: 20%;
}
.w-100 {
  width: 100%;
}
.loggedin-trainee-container {
  width: 100%;
}
.loggedin-trainee-logo {
  width: 30%;
  margin: 20px 35% 10px 35%;
}
.loggedin-trainee-details-container {
  width: 100%;
  padding: 12px;
  color: #000;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  text-align: center;
}
.clock-wrapper {
  width: 97%;
  background: rgb(255, 255, 255);
  text-align: center;
  padding: 15px 10px;
  border: thin solid rgb(238, 236, 236);
  margin: 2px;
  margin-right: 50px;
}
.clock-container {
  font-size: 35px;
  font-family: "Inter", sans-serif;
  letter-spacing: 7px;
  color: rgb(0, 0, 0);
}
.clock-container-new {
  font-size: 22px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  letter-spacing: 2px;
  color: rgb(0, 0, 0);
}
.question-list-wrapper {
  padding: 20px;
}
.question-list-inner {
  width: 100%;
  background: #fff;
  /* border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  border: thin solid rgb(238, 236, 236);
  margin: 2px;
}
.questionNoText {
  font-family: "Inter" sans-serif !important;
  font-size: 24px !important;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
}
.marksText {
  font-family: "Inter" sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
}
.questionBody {
  font-family: "Inter" sans-serif;
  font-size: 32px !important;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  margin-bottom: 0;
}
.qDf {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
}

.qDf_inner {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
  color: #000;
}

.question-list-inner-new {
  width: 100%;
  padding: 20px;
  margin: 2px;
}
.qb {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.question-holder {
  width: 80%;
  /* background: rgb(205, 217, 225); */
  position: absolute;
  min-height: 100%;
  padding: 10px;
}
.question-holder-new {
  background: linear-gradient(180deg, #ffffff 0%, #f1f1f1 100%);

  width: 95%;
  margin: auto;
  position: relative;
  /* min-height: 100%; */
  padding: 10px;
}
.single-question-container {
  width: 100%;
  background: rgb(255, 255, 255);
  /* border-radius: 5px; */
  padding: 30px;
  height: 1000px;
}

.single-question-container-new {
  width: 100%;
  position: relative;
  /* border-radius: 5px; */
  /* padding: 30px;
  height: 1000px; */
}
.clock {
  position: absolute;
  left: 50%;
  top: 10.5%;
  -webkit-transform: translate(-50%, -10%);
          transform: translate(-50%, -10%);
}
.quickResultModalParrent {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
}
.quickResultText {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: center;
  color: #535353;
}
.custom-progress {
  position: relative;
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.curcularProgressEmptyFill .ant-progress-circle-trail {
  stroke: #5a5a5a !important;
}
.progress-text-quickResult {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: #535353;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 2px;
  /* Adjust the font size as needed */
}
.zoomInText {
  width: -webkit-max-content;
  width: max-content;
}

/* @media (max-width: 1300px) {
  .clock {
    left: 43%;
    top: 10%;
    transform: translateY(-10%, -43%);
  }
}

@media (width: 1024px) {
  .clock {
    left: 41%;
    top: 11%;
    transform: translateY(-11%, -41%);
  }
} */

.vertical-text {
  -webkit-writing-mode: vertical-rl;
          writing-mode: vertical-rl;
  -webkit-transform: rotate(180);
          transform: rotate(180);
}
.testHeaderTimeText {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
  color: black;
}
.openlink {
  font-family: "Inter" sans-serif;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  color: #0f7dff;
  text-decoration: underline;
  text-align: center;
  cursor: pointer;
  z-index: 777;
}
.openLinkModalWrapper {
  background: #d9d9d9;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 20px;
  border: 20px solid #d9d9d9;
  /* border-color: #d9d9d9; */
}

.box {
  background-color: #f7ff00;
  color: black;
  padding: 20px;
  margin: 20px;
  display: inline-block;
  vertical-align: middle;
}
.side_panel {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0;
  z-index: 999;
  display: flex;
}
.side_Question {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  background-color: #f1f1f1;
  color: #000;
  border-top-left-radius: 35px;
  border-bottom-left-radius: 35px;
  width: 300px;
  height: 60vh;

  box-shadow: 0px 4px 4px 0px #00000040;
}
.control-button-in-exam-portal {
  width: 100%;
  /* text-align: right; */
}
.Question-single-body-holder {
  padding: 50px 0px;
  color: #fff;
}
.Single-question-body-image-container {
  width: 100%;
  height: 300px;
}
.Single-question-body-image-container-new {
  width: 100%;
  height: 300px;
  max-height: 175px;
  z-index: 9999999999 !important;
}
.Single-question-body-image {
  width: 40%;
  margin: 30px 30%;
}
.Question-single-option-panel {
  width: 100%;
}
.Single-option {
  min-height: 200px;
}

.Single-option-new {
  /* min-height: 200px; */
}
.option-image-in-exam-panel-holder {
  /* width: 100%; */
  width: 75%;
}
.option-image-in-exam-panel-holder-w0 {
  width: 0%;
}
.option-image-in-exam-panel {
  width: 60%;
  object-fit: contain;
  margin-left: 20%;
}
.End-test-container {
  width: 100%;
  text-align: center;
}

.radioQuestion .ant-radio-inner {
  background-color: #d9d9d9;
}
.radioQuestion .ant-radio-checked .ant-radio-inner:after {
  background-color: black;
  border-color: #000;
}

.radioQuestion .ant-radio:hover .ant-radio-inner {
  border-color: black;
  border-color: #000;
}
.radioQuestion .ant-radio:focus .ant-radio-inner:focus {
  border-color: black;
  border-color: #000;
}
.radioQuestion .ant-radio:active .ant-radio-inner:active {
  border-color: black;
  border-color: #000;
}

@media (max-device-width: 768px) {
  .Test-portal-not-started-yet-inner {
    width: 90%;
    min-height: 400px;
    margin-left: 5%;
  }
  .question-holder {
    width: 100%;
  }
}

@media (max-width: 1020px) {
  .questionNoText {
    font-family: "Inter" sans-serif !important;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
  }

  .questionBody {
    font-family: "Inter" sans-serif;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
  }
  .openlink {
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  .questionBody {
    font-size: 16px !important;
  }
}

@media (min-width: 1720px) {
  .questionNoText {
    font-size: 32px !important;
    font-family: "Inter" sans-serif !important;

    font-weight: 700;
  }
  .marksText {
    font-family: "Inter" sans-serif;
    font-size: 24px !important;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
  }
  .questionBody {
    font-family: "Inter" sans-serif;
    font-size: 48px !important;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
  }
  .clock {
    position: absolute;
    left: 50%;
    top: 11%;
    -webkit-transform: translate(-50%, -11%);
            transform: translate(-50%, -11%);
  }
  .openlink {
    font-size: 36px;
  }
}

/* @media (min-height: 1200px) {
  .clock {
    position: absolute;
    left: 50%;
    top: 10.5%;
    transform: translate(-50%, -11%);
  }
}

@media (min-height: 2500px) {
  .clock {
    position: absolute;
    left: 50%;
    top: 10.3%;
    transform: translate(-50%, -11%);
  }
} */

.quickResultInfo {
  display: none;
  flex-direction: column;
  width: 500px;
  border-radius: 45px;
  background: #d9d9d9;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 30px;
}
.quickResultInfoText {
  padding: 20px 30px;
  color: #5e5e5e;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.quickResultHoverInfoBox:hover .quickResultInfo {
  display: flex;
}
.quickResultHoverInfoBox {
  position: relative;
}

.profile_wrapper {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.profile_containers {
  width: 95%;
  /* margin: auto; */
  /* height: 90vh; */

  height: 100vh;
  overflow: auto;
}

.space-between {
  padding: 0% 0%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* .tabs {
  display: flex;
  justify-content: center;
  align-items: center;
} */
.edit_profile_container {
  display: flex;
  flex-direction: column;
}
.divier_edit {
  height: 2px !important;
  width: 98% !important;
  min-width: 98% !important;
}
.container_inner {
  width: 95%;
  margin: auto;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}
.profile_text {
  font-family: "Inter", sans-serif;
  font-size: 30px;
  font-weight: 800;
  line-height: 48px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #f1f1f1;
}

.tabs .ant-tabs-bar {
  background-color: transparent;
}

.tabs .ant-tabs-nav {
  margin-bottom: 0; /* Remove the bottom margin of the tab navigation */
}
.tabs .ant-tabs-nav .ant-tabs-tab {
  margin: 0 !important;
  margin-left: -2px !important;
}
.tabs .ant-tabs-tab {
  color: #f1f1f1; /* Set the color of the tab text */
  padding: 10px 40px !important;

  border-radius: 0px 0px 25px 25px;
  position: relative; /* Set position relative to create a context for the divider */

  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 700;
  /* line-height: 36px; */
  letter-spacing: 0em;
  text-align: center;
}

.tabs .ant-tabs-tab-active,
.tabs .ant-tabs-tab-active:focus {
  background-color: #f1f1f1;
  color: #4a525c !important;
  padding: 10px 40px !important;
}
.ant-tabs-ink-bar {
  background-color: transparent;
}
.tabs .ant-tabs-tab-prev-icon-target,
.tabs .ant-tabs-tab-next-icon-target {
  color: white;
  font-size: 18px;
}

/* .tabs .ant-tabs-tab:hover {
  background-color: transparent;
  color: #f1f1f1 !important;
  padding: 10px 40px !important;
} */

/* .tabs .ant-tabs-tab:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0px;
  height: 20%;
  width: 1px;
  background-color: #f1f1f1;
  transform: translateY(-50%);
} */

@media (max-width: 450px) {
  .tabs .ant-tabs-tab,
  .tabs .ant-tabs-tab-active,
  .tabs .ant-tabs-tab-active:focus {
    padding: 10px !important;
  }
}

.tabs .ant-tabs-nav {
  border-top: 3px solid #f1f1f1;
}
.label {
  margin-bottom: 0 !important;
}
.label .ant-form-item-label > label {
  color: #f1f1f1 !important;
  font-family: "Inter" sans-serif;
  font-weight: 400;
  letter-spacing: 0.05em;
  text-align: left;
}
.label .ant-form-item-label > label::after {
  display: none;
}
.input_editProfile {
  border-radius: 35px !important;
  height: 25px !important;
  box-shadow: 0px 4px 4px 0px #00000040 inset;
  color: black;
}
.input_editProfile input {
  border-radius: 35px !important;
  height: 25px !important;
  box-shadow: 0px 4px 4px 0px #00000040 inset;
  color: black;
}
.select_editProfile .ant-select-selection--single {
  border-radius: 35px !important;
  height: 25px !important;
  box-shadow: 0px 4px 4px 0px #00000040 inset;
  display: flex;
  align-items: center;
  color: black !important;
}
.detailsText_editProfile {
  font-family: "Inter" sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 39px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #f1f1f1;
}
.select_editProfile .ant-select-selection__placeholder {
  height: auto !important;
  position: absolute;
  top: 50%;
}
.ant-calendar-picker-input.ant-input {
  width: 100%;
  border-radius: 35px !important;
  height: 25px !important;
  box-shadow: 0px 4px 4px 0px #00000040 inset;
}
.ant-calendar-picker-icon {
  display: none;
}
.EditProfileSaveButton {
  font-family: "Inter" sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: center;
  background-color: #0082fb;
  color: #f1f1f1;
  width: 120px;
  height: 38px;
  border-color: #0082fb;
}

.EditProfileSaveButton:hover,
.EditProfileSaveButton:focus {
  background-color: #0082fb;
  color: #f1f1f1;
  border-color: #0082fb;
}

.EditProfileCancelButton {
  font-family: "Inter" sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
  background-color: transparent;
  color: #f1f1f1;
  width: 120px;
  height: 38px;
  border: 2px solid #f1f1f1;
}
.EditProfileCancelButton:hover,
.EditProfileCancelButton:focus {
  background-color: transparent;
  color: #f1f1f1;
  border: 2px solid #f1f1f1;
}

@media (min-width: 1700px) {
  .ant-tabs-tab {
    font-size: 30px;
  }
  .profile_text {
    font-size: 40px;
  }
  .input_editProfile,
  .input_editProfile input {
    height: 30px !important;
  }
  .select_editProfile .ant-select-selection--single {
    height: 30px !important;
  }
  .ant-calendar-picker-input.ant-input {
    height: 30px !important;
  }
  .select_editProfile .ant-select-selection__placeholder {
    height: auto !important;
  }
  .label .ant-form-item-label > label {
    font-size: 18px;
  }
  .detailsText_editProfile {
    font-size: 32px;
  }
  .EditProfileSaveButton {
    width: 173px;
    height: 48px;
    font-size: 24px;
  }
  .EditProfileCancelButton {
    width: 173px;
    height: 48px;
    font-size: 24px;
  }
}
@media (min-width: 769px) and (max-width: 1200px) {
  .ant-tabs-tab {
    font-size: 15px !important;
  }
  .profile_text {
    font-size: 22px !important;
  }
  .input_editProfile,
  .input_editProfile input {
    height: 20px !important;
  }
  .select_editProfile .ant-select-selection--single {
    height: 20px !important;
  }
  .ant-calendar-picker-input.ant-input {
    height: 20px !important;
  }
  .select_editProfile .ant-select-selection__placeholder {
    height: auto !important;
  }
  .detailsText_editProfile {
    font-size: 18px;
  }
  .EditProfileSaveButton {
    width: 100px;
    height: 38px;
  }
  .EditProfileCancelButton {
    width: 100px;
    height: 38px;
  }
  .container_inner {
    width: 100% !important;
  }
}

@media (min-width: 300px) and (max-width: 769px) {
  .ant-tabs-tab {
    font-size: 12px !important;
  }
  .profile_text {
    font-size: 18px !important;
  }
}
@media (max-width: 300px) {
  .profile_text {
    font-size: 14px !important;
  }
  .ant-tabs-tab {
    font-size: 12px !important;
  }
}

@media (min-width: 0px) and (max-width: 769px) {
  .input_editProfile,
  .input_editProfile input {
    height: 20px !important;
  }
  .select_editProfile .ant-select-selection--single {
    height: 20px !important;
  }
  .ant-calendar-picker-input.ant-input {
    height: 20px !important;
  }
  .select_editProfile .ant-select-selection__placeholder {
    height: auto !important;
  }
  .detailsText_editProfile {
    font-size: 16px;
  }
  .EditProfileSaveButton {
    width: auto;
    height: 38px;
  }
  .EditProfileCancelButton {
    width: auto;
    height: 38px;
  }
}

.zafar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rewardBox {
  background: linear-gradient(180deg, #40e883 0%, #349e5f 100%);
  padding: 2px 12px;
  border-radius: 20px;
  display: flex;
  grid-gap: 4px;
  gap: 4px;
  align-items: center;
}

.newtest-steps-holder {
  width: 80%;
  margin-left: 10%;
}
.new-test-form-holder {
  padding-top: 30px;
}
.basic-test-form-outer {
  width: 36%;
  margin-left: 33%;
  padding: 50px;
  background: linear-gradient(
    to right,
    rgb(183, 233, 247),
    rgb(219, 243, 250),
    rgb(245, 252, 255)
  );
  border-radius: 5px;
}
.list1-question-new-test {
  padding: 0px;
}
.random-question-generation {
  background: linear-gradient(
    to right,
    rgb(183, 233, 247),
    rgb(219, 243, 250),
    rgb(245, 252, 255)
  );
  width: 100%;
  border-radius: 5px;
  padding: 20px;
}
.blind {
  display: none;
}
.notblind {
  display: block;
}
.custom-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-y: hidden !important;
  grid-gap: 8;
  gap: 8;
}
.ant-transfer-list-content-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#selectHtml {
  display: "flex";
  flex-direction: row;
}
#selectHtml img {
  width: 40px !important;
  height: 40px !important;
  display: none !important;
}

#selectHtml > p {
  display: none !important;
}

#selectHtml > p:first-child {
  display: block !important;
}

.steps-action {
  margin-top: 24px;
}
.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  color: black;

  min-height: 200px;
  text-align: center;
  padding-top: 60px;
}
.steps-current-instruction {
  margin-top: 16px;
}

.welcome_main_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
}

.welcome_left {
  width: 40%;
  padding: 2rem;
}

.welcome_left h1 {
  font-size: 2rem;
  margin-bottom: 0;
}

.welcome_right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
}

.welcome_right img {
  width: 100%;
}

.welcome_right h1 {
  margin-top: 1.5rem;
  font-size: 2rem;
}

/* .admin_powers {
  margin-top: 20px;
} */
.admin_powers ul {
  padding-left: 32px;
}
.admin_powers li {
  font-weight: 600;
}

@media only screen and (max-width: 1024px) {
  .welcome_left {
    width: 90%;
  }

  .welcome_right {
    width: 90%;
  }
}

@media only screen and (max-width: 768px) {
  .welcome_left {
    width: 100%;
  }

  .welcome_right {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .welcome_left {
    padding: 0;
  }
}

.container {
  max-width: 1300px;
  margin: auto;
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  gap: 12px;
}
.stack {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
}
.name {
  color: #f1f1f1;
  text-align: center;
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 2px;
}
.stateWeekText {
  color: #f1f1f1;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}
.subjectFont {
  color: #f1f1f1;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

@media (max-width: 600px) {
  .subjectFont {
    font-size: 12px !important;
  }
}

.stateContainer {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  gap: 12px;
}
.progressLabel {
  color: #f1f1f1;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 1.6px;
}

.custom-progress {
  position: relative;
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: #f1f1f1;
  text-align: center;
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 2px;
  /* Adjust the font size as needed */
}
.trophyText {
  font-family: "Inter" sans-serif;
  font-size: 22px;
  color: white;
  font-weight: 800;
  letter-spacing: 0.05em;
  text-align: center;
  line-height: 1;
  text-shadow: 2px 2px 3px #00000080;
}
.wrapperBox {
  width: 100%;
  border: 1px solid #f1f1f1;
  padding: 20px 40px;
  /* padding-top: 20px;
  padding-left: 20px;
  padding-right: 40px; */
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  height: 230px;
  grid-gap: 8px;
  gap: 8px;
}
.wrapperBox2 {
  width: 100%;
  border: 1px solid #f1f1f1;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  height: 230px;
  grid-gap: 8px;
  gap: 8px;
}
.wrapperBox1 {
  width: 100%;
  border: 1px solid #f1f1f1;
  padding: 15px 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 230px;

  /* height: 280px; */
  grid-gap: 8px;
  gap: 8px;
}
.weeklyStateStack {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  grid-gap: 6px;
  gap: 6px;
}
.weeklystateText {
  font-family: "Inter" sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #f1f1f1;
  /* text-decoration: underline; */
}

.Chart {
  position: relative;
  width: 100%;
  /* height: 200px;
  overflow: hidden; */
}
.titleBox {
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  color: #f1f1f1;
}
.stateFooter {
  width: 70%;
  margin: auto;
}
.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footerInner {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
  padding: 16px;
}
.footerInnerTitle {
  color: #f1f1f1;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 2px;
}
.progressFooterLabel {
  color: #f1f1f1;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}
.progress-wrapper {
  /* position: relative; */
  cursor: pointer;
}
.progressInfo {
  position: absolute;
  bottom: 40px;
  /* transform: translate(-40px); */
  display: none;
  transition: display 0.5s ease;
}
.infoText {
  padding: 20px;
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
canvas {
  height: 150px !important;
  width: 100% !important;
}

.practicalInfo,
.countDownInfo {
  display: none;
  flex-direction: column;
  width: 100%;
  border-radius: 25px;
  bottom: 20px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  left: 0;
}
.hoverInfoBox:hover .practicalInfo {
  display: flex;
}

.hoverInfoBoxCountDown:hover .countDownInfo {
  display: flex;
}

@media (max-width: 600px) {
  .infoText {
    font-size: 12px;
  }
  .progress-wrapper:hover .progressInfo {
    width: 90% !important;
  }
}
.progress-wrapper:hover .progressInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 25px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.progress-wrapper .ant-progress-bg {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.progress-wrapper .ant-progress-inner {
  background-color: #9b9b9b;
}

.curcularProgressEmptyFill .ant-progress-circle-trail {
  stroke: #5a5a5a !important;
}
.ant-divider-horizontal {
  margin: 8px 0 !important;
}

/* //////////////////////////// MEDIA Quiries /////////////////////// */

@media (min-width: 1700px) {
  .name {
    font-size: 36px;
  }
  .stateWeekText {
    font-size: 24px;
  }
  .subjectFont {
    font-size: 22px;
  }
  .progressLabel {
    font-size: 28px;
  }
  .progress-text {
    font-size: 30px;
  }
  .footerInnerTitle {
    font-size: 28px;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .titleBox {
    font-size: 14px !important;
  }
  .weeklystateText {
    font-size: 14px !important;
  }
  .wrapperBox2 {
    padding: 10px !important;
  }
  .stateFooter {
    width: 90%;
    margin: auto;
  }
  .wrapperBox {
    padding: 10px;
  }
  .name {
    font-size: 20px;
  }
  .stateWeekText {
    font-size: 16px;
  }
  .subjectFont {
    font-size: 16px;
  }
  .progressLabel {
    font-size: 16px;
  }
  .progress-text {
    font-size: 18px;
  }
  .footerInnerTitle {
    font-size: 18px;
  }
}

@media (min-width: 0px) and (max-width: 769px) {
  .name {
    font-size: 18px;
  }
  .wrapperBox {
    padding: 0px;
  }
  .stateFooter {
    width: 95%;
    margin: auto;
  }
  .stateWeekText {
    font-size: 14px;
  }
  .subjectFont {
    font-size: 12px;
  }
  .progressLabel {
    font-size: 12px;
  }
  .progress-text {
    font-size: 14px;
  }
  .footerInnerTitle {
    font-size: 14px;
  }
}

.custom-progress {
  position: relative;
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: -1;
}

.recharts-cartesian-grid-vertical line {
  stroke: #fff;
}
.recharts-cartesian-grid-vertical:first-child {
  stroke: #000 !important;
}

.wrapperBoxState {
  width: 100%;
  border: 1px solid #f1f1f1;
  padding: 20px 45px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  height: 300px;
  grid-gap: 4px;
  gap: 4px;
  position: relative;
}
.dashed_border {
  width: 100%;
  height: 2px;
  z-index: 999;
  /* dashed border */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='white' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
}
.wrapperBoxStateComponenet {
  width: 100%;
  border: 1px solid #f1f1f1;
  padding: 20px 45px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  height: 300px;
  grid-gap: 4px;
  gap: 4px;
}

.wrapperBoxState1 {
  width: 100%;
  border: 1px solid #f1f1f1;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  height: 300px;
  overflow-y: auto;
  grid-gap: 4px;
  gap: 4px;
}

.titleBox {
  font-family: "Inter" sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  color: #f1f1f1;
}

.containerState {
  /* width: 500px;
  height: 400px;
  position: relative;
  margin: 50px auto; */
  width: 100%;
  position: relative;

  display: flex;
  flex-direction: row;
  /* position: relative; */
  /* overflow: scroll; */
  /* overflow: hidden; */
  overflow-x: auto;

  height: 178px;
  grid-gap: 24px;
  gap: 24px;
}

.containerState::-webkit-scrollbar {
  width: 6px;
  margin-top: 20px; /* Add the margin-top value you want */
}
.containerState::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #a19d9d;
  background: #a19d9d;
  border-radius: 10px;
}
.containerState::-webkit-scrollbar-thumb {
  background: #f1f1f1;
  border-radius: 10px;
}
.containerState::-webkit-scrollbar-thumb:hover {
  background: #f1f1f1;
}
.progress {
  width: 50px;
  position: relative;
  /* height: 170px; */
  /* margin: 15px 0px; */
  /* overflow-y: hidden; */
}

.barcontainer {
  background-color: #ffffff;
  position: relative;
  /* transform: translateY(-50%); */
  top: 0%;
  width: 30px;
  height: 120px;
  float: left;
  border-radius: 40px;
  box-shadow: 0px 4px 4px 0px #00000040 inset;

  /* //border darken(#98AFC7, 40%) 3px solid */
}

.bar {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 80%;
  border-radius: 40px;
  /* //border-top: 6px solid #FFF; */
  box-sizing: border-box;
  -webkit-animation: grow 1.5s ease-out forwards;
          animation: grow 1.5s ease-out forwards;
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
}
.bar-label {
  position: absolute;
  bottom: 0;
  line-height: 1;
  font-size: 10px;
  color: white;
  padding: 1px;
  text-wrap: wrap;
  overflow: hidden; /* Hide any overflowing content */
  text-overflow: ellipsis; /* Show an ellipsis (...) for truncated text */
  display: -webkit-box; /* Use the -webkit- prefix for older Webkit browsers */
  -webkit-line-clamp: 2; /* Limit the number of lines to 2 */
  -webkit-box-orient: vertical; /* Set the box orientation to vertical */
}
@-webkit-keyframes grow {
  from {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
}
@keyframes grow {
  from {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
}

.subjectTopicContainer {
  display: flex;
  flex-direction: column;
  grid-gap: 28px;
  gap: 28px;
}

.avgTopicsText {
  font-family: "Inter" sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0em;
  text-decoration: underline;
  text-underline-offset: 10px; /* Adjust the value as needed */
  text-align: left;
  color: #f1f1f1;
}

.topicHeading {
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  color: #f1f1f1;
}
.topicText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #f1f1f1;
  /* overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical; */
}
.dividerState {
  margin: 3px !important;
}
.topicText1 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #f1f1f1;
}

.paperTest {
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
  color: #f1f1f1;
}
.paraBox {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #f1f1f1;
}

.buttonBox {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.paperButton {
  background: #0082fb;
  height: 40px !important;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 136px;
}

@media (min-width: 0px) and (max-width: 600px) {
  .barcontainer {
    width: 15px;
  }
  .paperButton {
    width: auto;
    font-size: 16px;
  }
  .bar-label {
    font-size: 8px;
  }

  .topicText,
  .topicText1,
  .topicHeading {
    font-size: 12px;
  }
  .wrapperBoxStateComponenet {
    padding: 20px;
  }
  .titleBox {
    font-size: 14px;
  }
}

@media (min-width: 769px) and (max-width: 1300px) {
  .barcontainer {
    width: 20px;
  }
  .bar-label {
    font-size: 8px;
  }
  .paperButton {
    width: 80px;
    font-size: 14px !important;
  }

  .topicText,
  .topicText1,
  .topicHeading {
    font-size: 11px;
  }
  .wrapperBoxStateComponenet {
    padding: 20px;
  }
  .titleBox {
    font-size: 14px;
  }
}

.bar-details-box {
  display: none;
}
.barParrent {
  height: 97%;
  cursor: pointer;
  width: 50px;
  position: relative;
}

.barParrent:hover .bar-details-box {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
  position: absolute;
  background-color: white;
  padding: 8px;
  right: -10;
  z-index: 99999999;
  white-space: nowrap;
  min-width: 100%;
}

.barParrent:first-child:hover .bar-details-box {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
  position: absolute;
  background-color: white;
  padding: 8px;
  right: -10;
  z-index: 99999999;
  white-space: nowrap; /* Prevent text from wrapping */
  min-width: 100%; /* Ensure it expands to fit content */
}

/* Apply special styles for the last .barParrent element */
.barParrent:last-child:hover .bar-details-box {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
  position: absolute;
  background-color: white;
  padding: 8px;
  left: -120px !important;
  z-index: 99999999;
  white-space: nowrap; /* Prevent text from wrapping */
  min-width: 100%; /* Ensure it expands to fit content */
}
.topicBarHoverText {
  font-size: 12px;
  white-space: nowrap;
}

.uploadButton {
  width: 100%;
  padding: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0082fb;
  border-radius: 15px;
  color: white;
  bottom: -10px;
  z-index: 10;
  box-shadow: 0px 4px 4px 0px #00000040;

  position: absolute;
}
.uploadButtonText {
  font-family: Inter;
  font-size: 18px;
  font-weight: 300;
  color: white;
  text-align: center;
}
.justify-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.question_consultation_container {
  width: 90%;
  margin: auto;
  padding: 20px 0px;
}

.fileUpload {
  width: 90px;
  padding: 10px 0px;
  background-color: #0082fb;
  border-radius: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fileUploadNew {
  width: 160px;
  padding: 5px 0px;
  background-color: #d9d9d9;
  border-radius: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploadText {
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: white;
}

.uploadTextNew {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #9f9f9f;
  padding-left: 20px;
}
.stacks {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
}
.queryLabel {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0em;
  color: white;
  text-align: left;
}
.flex-gap {
  display: flex;
  grid-gap: 18px;
  gap: 18px;
  align-items: center;
  padding-top: 10px;
}
.subjectText {
  font-family: Inter;
  font-size: 28px;
  font-weight: 600;
  color: white;
  text-align: left;
}

.center {
  width: 100%;
  display: flex;
  justify-content: center;
}

.list_Question {
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  color: white;
  text-align: left;
}

.imageName {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: white;
}

.custom_table_consultation .ant-table-thead > tr > th {
  padding: 8px;
}
.custom_table_consultation .ant-table-tbody > tr > td {
  padding: 8px;
}
.custom_table_consultation .ant-table-placeholder {
  background-color: transparent;
}

.custom_table_consultation .ant-spin-container {
  position: relative;
  height: 100%;
}

.custom_table_consultation .ant-table-pagination.ant-pagination {
  display: none;
}

.even_row {
  background-color: transparent !important;
  color: white !important;
  padding: 2px !important;
}

.odd_row {
  background-color: transparent !important;
  color: white !important;
  padding: 2px !important;
}

.even_row:hover,
.odd_row:hover {
  background-color: transparent !important;
}
.ant-table-tbody > tr.even_row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background-color: transparent !important;
}

.ant-table-tbody > tr.odd_row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background-color: transparent !important;
}

.custom_header {
  background-color: transparent !important;
  color: white !important;
}

.absolute_icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

@media (min-width: 769px) and (max-width: 1200px) {
  .question_consultation_container {
    /* width: 100%; */
  }
  .uploadText {
    font-size: 16px;
  }

  .fileUpload {
    width: 60px;
    padding: 5px 0px;
  }
  .queryLabel {
    font-size: 16px;
    font-weight: 400;
  }
  .subjectText {
    font-size: 22px;
  }
}

.queryText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: black;
}

.rewardPoints {
  font-family: Inter;
  font-size: 56px;
  font-weight: 500;
  line-height: 77px;
  letter-spacing: 0.05em;
  text-align: left;
  color: white;
}

.pointsText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #bababa;
}

.divier_edit {
  height: 2px !important;
  width: 98% !important;
  min-width: 98% !important;
}

.reward_tabs {
  padding: 30px 0px;
}
.reward_tabs .ant-tabs-bar {
  background-color: transparent;
}

.reward_tabs .ant-tabs-nav {
  margin-bottom: 0; /* Remove the bottom margin of the tab navigation */
}
.reward_tabs .ant-tabs-nav .ant-tabs-tab {
  /* margin: 0 !important;
  margin-left: -2px !important; */
}
.reward_tabs .ant-tabs-tab {
  /* Set the color of the tab text */
  /* padding: 10px 40px !important; */
  background-color: #c7c7c7;
  color: #6b6b6b !important;

  border-radius: 30px;
  position: relative; /* Set position relative to create a context for the divider */

  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.05em;
  text-align: center;
}

.reward_tabs .ant-tabs-tab-active,
.treward_tabs .ant-tabs-tab-active:focus {
  background-color: #0082fb;
  color: #fff !important;
  /* padding: 10px 40px !important; */
}
.ant-tabs-ink-bar {
  background-color: transparent;
}
.reward_tabs .ant-tabs-tab-prev-icon-target,
.reward_tabs .ant-tabs-tab-next-icon-target {
  color: white;
  font-size: 18px;
}

@media (max-width: 450px) {
  .reward_tabs .ant-tabs-tab,
  .reward_tabs .ant-tabs-tab-active,
  .reward_tabs .ant-tabs-tab-active:focus {
    padding: 10px !important;
  }
}

.reward_tabs .ant-tabs-nav {
  /* border-top: 3px solid #f1f1f1; */
}

.reward_Card {
  background: #d9d9d9;
  box-shadow: 0px 4px 4px 0px #00000040;
  height: 50vh;
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 20px;
}
.rewardImgBox {
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  gap: 12px;
  /* height: 40vh; */
}
.rewardPointsBox {
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  gap: 12px;
  justify-content: center;
  align-items: center;
}
.gutter-row {
}

.rewardTitle {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #6b6b6b;
}

.rewardDesc {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #6b6b6b;
}
.rewardsPoints {
  font-family: Inter;
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #575757;
}

.reedemButton {
  background: #0082fb;
  color: white;
  border-radius: 30px;
  font-family: "Inter", sans-serif;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-align: center;
  height: 45px;
}
.reedemButtonCancel {
  background: transparent;
  color: #5e5e5e;
  border: 1px solid #5e5e5e;
  border-radius: 30px;
  font-family: "Inter", sans-serif;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-align: center;
  height: 45px;
}

.redemeActions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  grid-gap: 16px;

  gap: 16px;
}

.rewardsPointsPopup {
  font-family: Inter;
  font-size: 40px;
  font-weight: 400;
  line-height: 58px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #575757;
}

.reviewCost {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #828282;
}

.rewardMModal .ant-modal-content {
  background-color: #f1f1f1;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.text-container {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.cretaCateText {
  font-family: Inter;
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 10px;
}

.rewardMModalAdmin .ant-modal-content {
  background-color: #ffff;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.badgeParrent_wrapper {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.badgeLengthText {
  font-family: "Inter";
  font-size: 24px;
  font-weight: 400;
  line-height: 39px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #b4b4b4;
}
.badgeWrapper {
  padding: 20px 60px;
  background-color: #d9d9d9;
  border-radius: 20px;
}
.badge {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
  cursor: pointer;
}
.badgeHoverBox {
  display: none;
  z-index: 999;
  position: absolute;
  top: 0;
  right: -200px;
  max-width: 100%;
  width: 100%;
  min-width: 350px;
  flex-direction: column;
  /* justify-content: center; */
  grid-gap: 16px;
  gap: 16px;
  padding: 25px;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: #f1f1f1;
  border-radius: 40px;
}

.badge:hover .badgeHoverBox {
  display: flex;
}
.badgeTitle {
  font-family: Inter;
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #7b7b7b;
}

.badgeDesc {
  font-family: Inter;
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #7b7b7b;
}

.answer-table-outer {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  padding: 50px;
}
.pp {
  margin-top: 20px;
}

.answer-table-wrapper {
  width: 90%;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .answer-table-wrapper {
    width: 100%;
  }
  .answer-table-heading {
    width: 100%;
    text-align: center;
  }
  .answer-table-outer {
    width: 100%;
    padding: 50px;
  }
  .feedbackFormHolder {
    width: 50%;
    margin-left: 25%;
    text-align: center;
    margin-top: 50px;
  }
  .pp {
    padding: 15px;
  }
}
.score-label {
  font-size: 22px;
  text-align: center;
  font-weight: 500;
  height: 100px;
}
.score-label1 {
  font-size: 22px;
  text-align: center;
  font-weight: 500;
  height: 100px;
  width: 25%;
}

/* /////////////////////////////////////////////////////////////////////// */

.Question-single-container-new {
  border: 2px solid #000;
  border-radius: 50px;
  height: 100%;
  margin: auto;
  position: relative;
  /* min-height: 100%; */
  /* padding: 20px; */
  min-height: 80vh;
  max-height: 100%;
}
.SiderQuestionResult {
  background-color: #f1f1f1;
  width: 400px;
  min-height: 100vh;
  height: 100%;
  float: right;
  border-radius: 50px;
  border-left: 2px solid #000;
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  gap: 12px;
  padding: 40px 30px;
  z-index: 9999999;
}

.leftColQuestionResult {
  padding: 20px;
  z-index: 20 !important;
}
.resultParrentWrapper {
  display: flex;
}
.first {
  width: auto;
}
.mobileTool {
  z-index: 9999999999;
}
@media (max-width: 1020px) {
  .resultParrentWrapper {
    display: flex;
    flex-direction: column;
  }
  .first {
    width: auto;
  }
  .SiderQuestionResult {
    width: 100%;
  }
}
.first,
.second {
  flex: 1 1 auto;
}
.marksContainer {
  border: 2px solid #555555;
  border-radius: 20px;
}
.marksTitleO .ant-typography,
.marksTitleT .ant-typography,
.marksO .ant-typography,
.marksT .ant-typography,
.questionAns .ant-typography,
.questionAnsTitle .ant-typography {
  color: #1e1e1e !important;
}

.marksTitleO {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  border-right: 2px solid #555555;
  border-bottom: 2px solid #555555;
  color: #1e1e1e !important;
}
.marksTitleT {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  border-bottom: 2px solid #555555;
  color: #1e1e1e !important;
}
.marksO {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  border-right: 2px solid #555555;
  padding: 10px;
  color: #1e1e1e !important;
}
.marksT {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  padding: 10px;
  color: #1e1e1e !important;
}

@media (max-width: 600px) {
  .marksTitleO {
    font-size: 14px;
  }
  .marksTitleT {
    font-size: 14px;
  }
  .marksO {
    font-size: 14px;
  }
  .marksT {
    font-size: 14px;
  }
  .questionAnsTitle {
    font-size: 14px !important;
  }

  .questionAns {
    font-size: 14px !important;
    z-index: 10 !important;
  }
  .explanationModal {
    z-index: 9999999999 !important;
  }
}

.explanationModal {
  z-index: 9999999999 !important;
}
.explanationModal .ant-modal-content {
  background-color: #d9d9d9;
  box-shadow: none;
  -webkit-box-shadow: none;
}
.questionAnsTitle {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #1e1e1e !important;
}

.questionAns {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #1e1e1e !important;
}

.customRadio .ant-radio-inner {
  border-radius: 50%;
  background-color: transparent;
  width: 22px;
  height: 22px;
}
.customRadioTick .ant-radio-inner {
  border-radius: 50%;
  background-color: transparent;
  width: 22px;
  height: 22px;
}

.customRadio .ant-radio-inner::after {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  background: url(/static/media/cross.8d1ffa5d.svg) center center no-repeat;
}
.customRadioTick .ant-radio-inner::after {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  background: url(/static/media/ticksymbol.2d4788ce.svg) center center no-repeat;
}

@media only screen and (max-device-width: 720px) {
  #root {
    overflow-x: hidden;
  }

  .answer-table-wrapper {
    width: 200%;
  }

  .ant-spin-nested-loading {
    width: 610px;
  }

  .ant-table-wrapper {
    overflow: scroll;
    width: 330px;
  }

  .feedbackFormHolder {
    position: absolute;
  }

  .ant-descriptions.ant-descriptions-small.ant-descriptions-bordered {
    position: -webkit-sticky;
    position: sticky;
    width: 45%;
  }

  .answer-table-heading {
    width: 100%;
    text-align: center;
  }

  .answer-table-outer {
    width: 100%;
    padding: 5px;
  }

  .ant-input {
    padding-right: 180px;
  }
}

@media only screen and (max-device-width: 720px) {
  .ant-col-20 {
    padding-left: 20px;
  }
}

