.rewardPoints {
  font-family: Inter;
  font-size: 56px;
  font-weight: 500;
  line-height: 77px;
  letter-spacing: 0.05em;
  text-align: left;
  color: white;
}

.pointsText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #bababa;
}

.divier_edit {
  height: 2px !important;
  width: 98% !important;
  min-width: 98% !important;
}

.reward_tabs {
  padding: 30px 0px;
}
.reward_tabs .ant-tabs-bar {
  background-color: transparent;
}

.reward_tabs .ant-tabs-nav {
  margin-bottom: 0; /* Remove the bottom margin of the tab navigation */
}
.reward_tabs .ant-tabs-nav .ant-tabs-tab {
  /* margin: 0 !important;
  margin-left: -2px !important; */
}
.reward_tabs .ant-tabs-tab {
  /* Set the color of the tab text */
  /* padding: 10px 40px !important; */
  background-color: #c7c7c7;
  color: #6b6b6b !important;

  border-radius: 30px;
  position: relative; /* Set position relative to create a context for the divider */

  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.05em;
  text-align: center;
}

.reward_tabs .ant-tabs-tab-active,
.treward_tabs .ant-tabs-tab-active:focus {
  background-color: #0082fb;
  color: #fff !important;
  /* padding: 10px 40px !important; */
}
.ant-tabs-ink-bar {
  background-color: transparent;
}
.reward_tabs .ant-tabs-tab-prev-icon-target,
.reward_tabs .ant-tabs-tab-next-icon-target {
  color: white;
  font-size: 18px;
}

@media (max-width: 450px) {
  .reward_tabs .ant-tabs-tab,
  .reward_tabs .ant-tabs-tab-active,
  .reward_tabs .ant-tabs-tab-active:focus {
    padding: 10px !important;
  }
}

.reward_tabs .ant-tabs-nav {
  /* border-top: 3px solid #f1f1f1; */
}

.reward_Card {
  background: #d9d9d9;
  box-shadow: 0px 4px 4px 0px #00000040;
  height: 50vh;
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 20px;
}
.rewardImgBox {
  display: flex;
  flex-direction: column;
  gap: 12px;
  /* height: 40vh; */
}
.rewardPointsBox {
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: center;
}
.gutter-row {
}

.rewardTitle {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #6b6b6b;
}

.rewardDesc {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #6b6b6b;
}
.rewardsPoints {
  font-family: Inter;
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #575757;
}

.reedemButton {
  background: #0082fb;
  color: white;
  border-radius: 30px;
  font-family: "Inter", sans-serif;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-align: center;
  height: 45px;
}
.reedemButtonCancel {
  background: transparent;
  color: #5e5e5e;
  border: 1px solid #5e5e5e;
  border-radius: 30px;
  font-family: "Inter", sans-serif;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-align: center;
  height: 45px;
}

.redemeActions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  gap: 16px;
}

.rewardsPointsPopup {
  font-family: Inter;
  font-size: 40px;
  font-weight: 400;
  line-height: 58px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #575757;
}

.reviewCost {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #828282;
}

.rewardMModal .ant-modal-content {
  background-color: #f1f1f1;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.text-container {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
