#listHTML {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}
#listHTML img {
  height: 100px !important;
  width: 100px !important;
  margin-left: 40px !important;
}
