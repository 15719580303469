.profile_wrapper {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.profile_containers {
  width: 95%;
  /* margin: auto; */
  /* height: 90vh; */

  height: 100vh;
  overflow: auto;
}

.space-between {
  padding: 0% 0%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* .tabs {
  display: flex;
  justify-content: center;
  align-items: center;
} */
.edit_profile_container {
  display: flex;
  flex-direction: column;
}
.divier_edit {
  height: 2px !important;
  width: 98% !important;
  min-width: 98% !important;
}
.container_inner {
  width: 95%;
  margin: auto;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.profile_text {
  font-family: "Inter", sans-serif;
  font-size: 30px;
  font-weight: 800;
  line-height: 48px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #f1f1f1;
}

.tabs .ant-tabs-bar {
  background-color: transparent;
}

.tabs .ant-tabs-nav {
  margin-bottom: 0; /* Remove the bottom margin of the tab navigation */
}
.tabs .ant-tabs-nav .ant-tabs-tab {
  margin: 0 !important;
  margin-left: -2px !important;
}
.tabs .ant-tabs-tab {
  color: #f1f1f1; /* Set the color of the tab text */
  padding: 10px 40px !important;

  border-radius: 0px 0px 25px 25px;
  position: relative; /* Set position relative to create a context for the divider */

  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 700;
  /* line-height: 36px; */
  letter-spacing: 0em;
  text-align: center;
}

.tabs .ant-tabs-tab-active,
.tabs .ant-tabs-tab-active:focus {
  background-color: #f1f1f1;
  color: #4a525c !important;
  padding: 10px 40px !important;
}
.ant-tabs-ink-bar {
  background-color: transparent;
}
.tabs .ant-tabs-tab-prev-icon-target,
.tabs .ant-tabs-tab-next-icon-target {
  color: white;
  font-size: 18px;
}

/* .tabs .ant-tabs-tab:hover {
  background-color: transparent;
  color: #f1f1f1 !important;
  padding: 10px 40px !important;
} */

/* .tabs .ant-tabs-tab:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0px;
  height: 20%;
  width: 1px;
  background-color: #f1f1f1;
  transform: translateY(-50%);
} */

@media (max-width: 450px) {
  .tabs .ant-tabs-tab,
  .tabs .ant-tabs-tab-active,
  .tabs .ant-tabs-tab-active:focus {
    padding: 10px !important;
  }
}

.tabs .ant-tabs-nav {
  border-top: 3px solid #f1f1f1;
}
.label {
  margin-bottom: 0 !important;
}
.label .ant-form-item-label > label {
  color: #f1f1f1 !important;
  font-family: "Inter" sans-serif;
  font-weight: 400;
  letter-spacing: 0.05em;
  text-align: left;
}
.label .ant-form-item-label > label::after {
  display: none;
}
.input_editProfile {
  border-radius: 35px !important;
  height: 25px !important;
  box-shadow: 0px 4px 4px 0px #00000040 inset;
  color: black;
}
.input_editProfile input {
  border-radius: 35px !important;
  height: 25px !important;
  box-shadow: 0px 4px 4px 0px #00000040 inset;
  color: black;
}
.select_editProfile .ant-select-selection--single {
  border-radius: 35px !important;
  height: 25px !important;
  box-shadow: 0px 4px 4px 0px #00000040 inset;
  display: flex;
  align-items: center;
  color: black !important;
}
.detailsText_editProfile {
  font-family: "Inter" sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 39px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #f1f1f1;
}
.select_editProfile .ant-select-selection__placeholder {
  height: auto !important;
  position: absolute;
  top: 50%;
}
.ant-calendar-picker-input.ant-input {
  width: 100%;
  border-radius: 35px !important;
  height: 25px !important;
  box-shadow: 0px 4px 4px 0px #00000040 inset;
}
.ant-calendar-picker-icon {
  display: none;
}
.EditProfileSaveButton {
  font-family: "Inter" sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: center;
  background-color: #0082fb;
  color: #f1f1f1;
  width: 120px;
  height: 38px;
  border-color: #0082fb;
}

.EditProfileSaveButton:hover,
.EditProfileSaveButton:focus {
  background-color: #0082fb;
  color: #f1f1f1;
  border-color: #0082fb;
}

.EditProfileCancelButton {
  font-family: "Inter" sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
  background-color: transparent;
  color: #f1f1f1;
  width: 120px;
  height: 38px;
  border: 2px solid #f1f1f1;
}
.EditProfileCancelButton:hover,
.EditProfileCancelButton:focus {
  background-color: transparent;
  color: #f1f1f1;
  border: 2px solid #f1f1f1;
}

@media (min-width: 1700px) {
  .ant-tabs-tab {
    font-size: 30px;
  }
  .profile_text {
    font-size: 40px;
  }
  .input_editProfile,
  .input_editProfile input {
    height: 30px !important;
  }
  .select_editProfile .ant-select-selection--single {
    height: 30px !important;
  }
  .ant-calendar-picker-input.ant-input {
    height: 30px !important;
  }
  .select_editProfile .ant-select-selection__placeholder {
    height: auto !important;
  }
  .label .ant-form-item-label > label {
    font-size: 18px;
  }
  .detailsText_editProfile {
    font-size: 32px;
  }
  .EditProfileSaveButton {
    width: 173px;
    height: 48px;
    font-size: 24px;
  }
  .EditProfileCancelButton {
    width: 173px;
    height: 48px;
    font-size: 24px;
  }
}
@media (min-width: 769px) and (max-width: 1200px) {
  .ant-tabs-tab {
    font-size: 15px !important;
  }
  .profile_text {
    font-size: 22px !important;
  }
  .input_editProfile,
  .input_editProfile input {
    height: 20px !important;
  }
  .select_editProfile .ant-select-selection--single {
    height: 20px !important;
  }
  .ant-calendar-picker-input.ant-input {
    height: 20px !important;
  }
  .select_editProfile .ant-select-selection__placeholder {
    height: auto !important;
  }
  .detailsText_editProfile {
    font-size: 18px;
  }
  .EditProfileSaveButton {
    width: 100px;
    height: 38px;
  }
  .EditProfileCancelButton {
    width: 100px;
    height: 38px;
  }
  .container_inner {
    width: 100% !important;
  }
}

@media (min-width: 300px) and (max-width: 769px) {
  .ant-tabs-tab {
    font-size: 12px !important;
  }
  .profile_text {
    font-size: 18px !important;
  }
}
@media (max-width: 300px) {
  .profile_text {
    font-size: 14px !important;
  }
  .ant-tabs-tab {
    font-size: 12px !important;
  }
}

@media (min-width: 0px) and (max-width: 769px) {
  .input_editProfile,
  .input_editProfile input {
    height: 20px !important;
  }
  .select_editProfile .ant-select-selection--single {
    height: 20px !important;
  }
  .ant-calendar-picker-input.ant-input {
    height: 20px !important;
  }
  .select_editProfile .ant-select-selection__placeholder {
    height: auto !important;
  }
  .detailsText_editProfile {
    font-size: 16px;
  }
  .EditProfileSaveButton {
    width: auto;
    height: 38px;
  }
  .EditProfileCancelButton {
    width: auto;
    height: 38px;
  }
}

.zafar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rewardBox {
  background: linear-gradient(180deg, #40e883 0%, #349e5f 100%);
  padding: 2px 12px;
  border-radius: 20px;
  display: flex;
  gap: 4px;
  align-items: center;
}
