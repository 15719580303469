.cretaCateText {
  font-family: Inter;
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 10px;
}

.rewardMModalAdmin .ant-modal-content {
  background-color: #ffff;
  box-shadow: none;
  -webkit-box-shadow: none;
}
