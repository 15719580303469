.admin-table-container {
  width: 100%;
  padding: 20px;
  min-height: 80vh;
}

.ansInput {
  box-shadow: 0px 4px 4px 0px #00000040 inset;
}

.tableHeadTextInvoice {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #5e5e5e;
}
