.questionDetailsBody {
  padding: 30px;
}
.mainQuestionDetailsContaine {
  width: 100%;
}

.questionDetailsImage {
  /* max-width: 100%; */
  max-height: 200px;
}
.QuestionDetailsOptions {
  width: 100%;
  margin-bottom: 40px;
}
