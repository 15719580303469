.headerParrentWrapper {
  display: flex;

  gap: 12px;
  /* width: 95%;
  margin: auto; */
  padding: 10px 0px;
  align-items: flex-end;
}
.headerLeft {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  width: 10%;
}
.headerInner {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 80%;
  font-family: "Inter", sans-serif;
}
.headerRight {
  display: flex;
  justify-content: center;
  gap: 4px;
  width: 10%;
}
.hederTestName {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 39px;
  letter-spacing: 0.05em;
  text-align: center;
  color: black;
}
@media (max-width: 600px) {
  .hederTestName {
    font-size: 16px;
  }
  .testHeaderTimeText {
    font-size: 16px !important;
  }
  .headerInner {
    width: 40%;
  }
  .headerParrentWrapper {
    display: flex;
    justify-content: space-evenly;
  }
}
.testHeaderButton {
  background: #0082fb;
  height: 40px !important;
  font-family: "Inder", sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
}
@media (max-width: 600px) {
  .testHeaderButton {
    width: 20px;
  }
}

.testHeaderButtonFinish {
  background: #0082fb;
  height: 40px !important;
  font-family: "Inder", sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 140px;
}

@media (max-width: 600px) {
  .testHeaderButtonFinish {
    width: 100%;
    min-width: 80px;
  }
}

@media (min-width: 1720px) {
  .testHeaderButton {
    background: #0082fb;
    height: 61px !important;
    font-family: "Inder", sans-serif;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: center;
    width: 190px;
  }
  .testHeaderButtonFinish {
    background: #0082fb;
    height: 61px !important;
    font-family: "Inder", sans-serif;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: center;
    width: 190px;
  }
  .exit {
    font-size: 20px;
  }
}

@media (max-width: 1020px) {
  .testHeaderButton {
    height: 30px !important;
    font-size: 14px;
    font-family: "Inder", sans-serif;
    width: 80px;
  }
  .testHeaderButtonFinish {
    height: 30px !important;
    font-size: 14px;
    font-family: "Inder", sans-serif;
    width: 80px;
  }
  .exit {
    font-size: 14px !important;
  }
}

.testHeaderTimeText {
  font-family: "Inter", sans-serif !important;
  font-size: 18px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
  color: black;
}
.testHeaderDivider {
  margin: 0px !important;
  border: 1px solid #1e1e1e;
}
.exit {
  background-color: transparent;
  outline: none;
  text-decoration: underline;
  color: #0082fb;
  border: none;
  font-family: "Inter" sans-serif !important;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  display: inline-block;
  white-space: nowrap;
}
