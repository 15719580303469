.parallax {
  /* background-image: url("./homepage.jpg"); */
  height: 100vh;
  /* background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.home-page-container {
  width: 100%;
  background-image: url(../../../assets/login_page_bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
