@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inder&family=Inter:wght@300;400;500;600;700;800&display=swap");

body {
  font-family: "Inter" sans-serif;
}

@import "~antd/dist/antd.css";
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: rgb(0, 95, 135);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: rgb(0, 70, 105);
}
.fileUpload-input {
  content: "Select some files";
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 12px 6px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
.fileUpload-input::-webkit-file-upload-button {
  visibility: hidden;
}
.fileUpload-input:hover::before {
  border-color: black;
}
.fileUpload-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.questionDetailsImageContainer {
  width: 100%;
  height: 300px;
  padding: 30px 150px;
}
.image-tag {
  width: auto;
  height: 100%;
  object-fit: contain;
  max-width: 500px;
  z-index: 999999999 !important;
}

@media (max-width: 600px) {
  .image-tag {
    width: 200px;
    height: 100%;
    object-fit: contain;
    z-index: 999999999 !important;
  }
}

.space-btw {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.login-btn {
  background: linear-gradient(
      0deg,
      rgba(217, 217, 217, 0),
      rgba(217, 217, 217, 0)
    ),
    linear-gradient(0deg, #f1f1f1, #f1f1f1);
  border: 1px solid #f1f1f1 !important;
  border-radius: 45px !important;
  box-shadow: none !important;
  padding: 10px 40px;
  margin-bottom: 20px;
}
.login-btn:hover {
  background: linear-gradient(
      0deg,
      rgba(217, 217, 217, 0),
      rgba(217, 217, 217, 0)
    ),
    linear-gradient(0deg, #f1f1f1, #f1f1f1);
  border: 1px solid #f1f1f1 !important;
  border-radius: 45px !important;
  box-shadow: none !important;
  padding: 10px 40px;
  margin-bottom: 20px;
  color: white;
}

.register-btn {
  background: linear-gradient(
      0deg,
      rgba(217, 217, 217, 0),
      rgba(217, 217, 217, 0)
    ),
    linear-gradient(0deg, #f1f1f1, #f1f1f1);
  border-radius: 45px !important;
  box-shadow: none !important;
  padding: 10px 40px;
  margin-bottom: 20px;
}
.register-btn:hover {
  background: linear-gradient(
      0deg,
      rgba(217, 217, 217, 0),
      rgba(217, 217, 217, 0)
    ),
    linear-gradient(0deg, #f1f1f1, #f1f1f1);
  border: 1px solid #f1f1f1 !important;
  border-radius: 45px !important;
  box-shadow: none !important;
  padding: 10px 40px;
  margin-bottom: 20px;
  color: white;
}
.login-log {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  z-index: 1999;
  margin-top: -70px;
}
@media (max-width: 300px) {
  .login-log {
    margin-top: -30px;
  }
}

.ant-tabs-ink-bar {
  background: none;
}
