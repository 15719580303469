.steps-action {
  margin-top: 24px;
}
.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  color: black;

  min-height: 200px;
  text-align: center;
  padding-top: 60px;
}
.steps-current-instruction {
  margin-top: 16px;
}

.welcome_main_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
}

.welcome_left {
  width: 40%;
  padding: 2rem;
}

.welcome_left h1 {
  font-size: 2rem;
  margin-bottom: 0;
}

.welcome_right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
}

.welcome_right img {
  width: 100%;
}

.welcome_right h1 {
  margin-top: 1.5rem;
  font-size: 2rem;
}

/* .admin_powers {
  margin-top: 20px;
} */
.admin_powers ul {
  padding-left: 32px;
}
.admin_powers li {
  font-weight: 600;
}

@media only screen and (max-width: 1024px) {
  .welcome_left {
    width: 90%;
  }

  .welcome_right {
    width: 90%;
  }
}

@media only screen and (max-width: 768px) {
  .welcome_left {
    width: 100%;
  }

  .welcome_right {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .welcome_left {
    padding: 0;
  }
}
