#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

.logo11 {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 24px 16px 0;
  float: left;
  z-index: 10;
}

.d-logo {
  height: 55px;
  margin-top: -17px;
}
.user-options-list {
  position: fixed;
  top: 0px;
  right: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  list-style: none;
  float: left;
}
.user-options-list > * {
  display: inline;
  margin-right: 15px;
}

.sideOpenIcon {
  position: absolute;
  left: 2%;
  color: white;
}

@media (min-width: 0px) and (max-width: 769px) {
  .sider-wrapper {
    width: 0% !important;
    display: none;
  }
}

@media (min-width: 860px) {
  .siderToggle {
    display: none;
  }
}
