.answer-table-outer {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  padding: 50px;
}
.pp {
  margin-top: 20px;
}

.answer-table-wrapper {
  width: 90%;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .answer-table-wrapper {
    width: 100%;
  }
  .answer-table-heading {
    width: 100%;
    text-align: center;
  }
  .answer-table-outer {
    width: 100%;
    padding: 50px;
  }
  .feedbackFormHolder {
    width: 50%;
    margin-left: 25%;
    text-align: center;
    margin-top: 50px;
  }
  .pp {
    padding: 15px;
  }
}
.score-label {
  font-size: 22px;
  text-align: center;
  font-weight: 500;
  height: 100px;
}
.score-label1 {
  font-size: 22px;
  text-align: center;
  font-weight: 500;
  height: 100px;
  width: 25%;
}

/* /////////////////////////////////////////////////////////////////////// */

.Question-single-container-new {
  border: 2px solid #000;
  border-radius: 50px;
  height: 100%;
  margin: auto;
  position: relative;
  /* min-height: 100%; */
  /* padding: 20px; */
  min-height: 80vh;
  max-height: 100%;
}
.SiderQuestionResult {
  background-color: #f1f1f1;
  width: 400px;
  min-height: 100vh;
  height: 100%;
  float: right;
  border-radius: 50px;
  border-left: 2px solid #000;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 40px 30px;
  z-index: 9999999;
}

.leftColQuestionResult {
  padding: 20px;
  z-index: 20 !important;
}
.resultParrentWrapper {
  display: flex;
}
.first {
  width: auto;
}
.mobileTool {
  z-index: 9999999999;
}
@media (max-width: 1020px) {
  .resultParrentWrapper {
    display: flex;
    flex-direction: column;
  }
  .first {
    width: auto;
  }
  .SiderQuestionResult {
    width: 100%;
  }
}
.first,
.second {
  flex: 1 1 auto;
}
.marksContainer {
  border: 2px solid #555555;
  border-radius: 20px;
}
.marksTitleO .ant-typography,
.marksTitleT .ant-typography,
.marksO .ant-typography,
.marksT .ant-typography,
.questionAns .ant-typography,
.questionAnsTitle .ant-typography {
  color: #1e1e1e !important;
}

.marksTitleO {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  border-right: 2px solid #555555;
  border-bottom: 2px solid #555555;
  color: #1e1e1e !important;
}
.marksTitleT {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  border-bottom: 2px solid #555555;
  color: #1e1e1e !important;
}
.marksO {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  border-right: 2px solid #555555;
  padding: 10px;
  color: #1e1e1e !important;
}
.marksT {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  padding: 10px;
  color: #1e1e1e !important;
}

@media (max-width: 600px) {
  .marksTitleO {
    font-size: 14px;
  }
  .marksTitleT {
    font-size: 14px;
  }
  .marksO {
    font-size: 14px;
  }
  .marksT {
    font-size: 14px;
  }
  .questionAnsTitle {
    font-size: 14px !important;
  }

  .questionAns {
    font-size: 14px !important;
    z-index: 10 !important;
  }
  .explanationModal {
    z-index: 9999999999 !important;
  }
}

.explanationModal {
  z-index: 9999999999 !important;
}
.explanationModal .ant-modal-content {
  background-color: #d9d9d9;
  box-shadow: none;
  -webkit-box-shadow: none;
}
.questionAnsTitle {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #1e1e1e !important;
}

.questionAns {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #1e1e1e !important;
}

.customRadio .ant-radio-inner {
  border-radius: 50%;
  background-color: transparent;
  width: 22px;
  height: 22px;
}
.customRadioTick .ant-radio-inner {
  border-radius: 50%;
  background-color: transparent;
  width: 22px;
  height: 22px;
}

.customRadio .ant-radio-inner::after {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  background: url(../../../assets/cross.svg) center center no-repeat;
}
.customRadioTick .ant-radio-inner::after {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  background: url(../../../assets/ticksymbol.svg) center center no-repeat;
}
