.badgeParrent_wrapper {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.badgeLengthText {
  font-family: "Inter";
  font-size: 24px;
  font-weight: 400;
  line-height: 39px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #b4b4b4;
}
.badgeWrapper {
  padding: 20px 60px;
  background-color: #d9d9d9;
  border-radius: 20px;
}
.badge {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  cursor: pointer;
}
.badgeHoverBox {
  display: none;
  z-index: 999;
  position: absolute;
  top: 0;
  right: -200px;
  max-width: 100%;
  width: 100%;
  min-width: 350px;
  flex-direction: column;
  /* justify-content: center; */
  gap: 16px;
  padding: 25px;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: #f1f1f1;
  border-radius: 40px;
}

.badge:hover .badgeHoverBox {
  display: flex;
}
.badgeTitle {
  font-family: Inter;
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #7b7b7b;
}

.badgeDesc {
  font-family: Inter;
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #7b7b7b;
}
