@import url("https://fonts.googleapis.com/css?family=Orbitron&display=swap");
@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inder&family=Inter:wght@300;400;500;600;700;800&display=swap");

.skeletor-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 100px;
}
.Test-portal-not-started-yet-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgb(205, 217, 225);
}
.Test-portal-not-started-yet-inner {
  width: 50%;
  min-height: 200px;
  background: linear-gradient(
    to right,
    rgb(183, 233, 247),
    rgb(219, 243, 250),
    rgb(245, 252, 255)
  );
  margin-left: 25%;
  position: absolute;
  top: 50%;
  bottom: 50%;
  transform: translateY(-50%);
  border-radius: 5px;
  padding: 50px;
  vertical-align: middle;
}
.Test-portal-not-started-yet-inner-message {
  color: #fff;
  text-align: center;
}
.instaruction-page-wrapper {
  width: 100%;
  background: rgb(205, 217, 225);
  min-height: 100vh;
  padding: 50px 5%;
}
.instruction-page-inner {
  width: 100%;
  border: 3px solid rgb(0, 153, 203);
  border-radius: 5px;
  padding: 10px;
}
.proceed-to-test-button {
  width: 100%;
  padding: 20px 0px 40px 0px;
}
.exam-dashboard-wrapper {
  width: 100%;
  /* background: rgb(205, 217, 225); */
  position: absolute;
  /* height: 100vh; */
  min-height: 100%;
}
.exam-dashboard-wrapper-new {
  background: linear-gradient(180deg, #ffffff 0%, #f1f1f1 100%);

  width: 100%;
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  gap: 8px;
}
.ansInput {
  box-shadow: 0px 4px 4px 0px #00000040 inset;
}
/* .ansInputp {
  width: 380px;
}
@media (max-width: 768px) {
  .ansInputp {
    width: 200px;
  }
} */
.ansInputp .ant-input:focus {
  box-shadow: 0px 4px 4px 0px #00000040 inset;
  border-color: #ffff !important;
}
.ansInputp .ant-input:active {
  box-shadow: 0px 4px 4px 0px #00000040 inset;
  border-color: #ffff !important;
}
.ansInputp .ant-input:hover {
  box-shadow: 0px 4px 4px 0px #00000040 inset;
  border-color: #fff !important;
}
.ImagebgTest {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0 !important;
}
.explanationText {
  position: absolute;
  z-index: 200000000;
  left: 50%;
  top: 1%;
  transform: translate(-50%, -1%);
  cursor: pointer;
}
.editButton {
  position: absolute;
  z-index: 20;
  left: 50%;
  top: 1%;
  transform: translate(-50%, -1%);
  cursor: pointer;
}
.editBox {
  background-color: #0082fb;
  color: white;
  padding: 8px 18px;
  width: 100px;
  display: flex;
  justify-content: center;
  border-radius: 20px;
}
.correct-answere {
  color: green;
  font-size: 22px;
}
.your-answere {
  color: red;
  font-size: 22px;
}
.MarkingModalBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  background-color: #d9d9d9;
}

.markingTitle {
  font-family: Inter;
  font-size: 28px;
  font-weight: 300;
  text-align: center;
  color: #000000;
}
.markingPara {
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}
.textToggele {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
}

.side-panel-in-exam-dashboard {
  /*background: rgb(65,170,200);*/
  /* background: #009999; */
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  position: fixed;
  right: 0;
  height: 100%;
}
.w-20 {
  width: 20%;
}
.w-100 {
  width: 100%;
}
.loggedin-trainee-container {
  width: 100%;
}
.loggedin-trainee-logo {
  width: 30%;
  margin: 20px 35% 10px 35%;
}
.loggedin-trainee-details-container {
  width: 100%;
  padding: 12px;
  color: #000;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  text-align: center;
}
.clock-wrapper {
  width: 97%;
  background: rgb(255, 255, 255);
  text-align: center;
  padding: 15px 10px;
  border: thin solid rgb(238, 236, 236);
  margin: 2px;
  margin-right: 50px;
}
.clock-container {
  font-size: 35px;
  font-family: "Inter", sans-serif;
  letter-spacing: 7px;
  color: rgb(0, 0, 0);
}
.clock-container-new {
  font-size: 22px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  letter-spacing: 2px;
  color: rgb(0, 0, 0);
}
.question-list-wrapper {
  padding: 20px;
}
.question-list-inner {
  width: 100%;
  background: #fff;
  /* border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  border: thin solid rgb(238, 236, 236);
  margin: 2px;
}
.questionNoText {
  font-family: "Inter" sans-serif !important;
  font-size: 24px !important;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
}
.marksText {
  font-family: "Inter" sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
}
.questionBody {
  font-family: "Inter" sans-serif;
  font-size: 32px !important;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  margin-bottom: 0;
}
.qDf {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
}

.qDf_inner {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
  color: #000;
}

.question-list-inner-new {
  width: 100%;
  padding: 20px;
  margin: 2px;
}
.qb {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.question-holder {
  width: 80%;
  /* background: rgb(205, 217, 225); */
  position: absolute;
  min-height: 100%;
  padding: 10px;
}
.question-holder-new {
  background: linear-gradient(180deg, #ffffff 0%, #f1f1f1 100%);

  width: 95%;
  margin: auto;
  position: relative;
  /* min-height: 100%; */
  padding: 10px;
}
.single-question-container {
  width: 100%;
  background: rgb(255, 255, 255);
  /* border-radius: 5px; */
  padding: 30px;
  height: 1000px;
}

.single-question-container-new {
  width: 100%;
  position: relative;
  /* border-radius: 5px; */
  /* padding: 30px;
  height: 1000px; */
}
.clock {
  position: absolute;
  left: 50%;
  top: 10.5%;
  transform: translate(-50%, -10%);
}
.quickResultModalParrent {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
}
.quickResultText {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: center;
  color: #535353;
}
.custom-progress {
  position: relative;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.curcularProgressEmptyFill .ant-progress-circle-trail {
  stroke: #5a5a5a !important;
}
.progress-text-quickResult {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #535353;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 2px;
  /* Adjust the font size as needed */
}
.zoomInText {
  width: max-content;
}

/* @media (max-width: 1300px) {
  .clock {
    left: 43%;
    top: 10%;
    transform: translateY(-10%, -43%);
  }
}

@media (width: 1024px) {
  .clock {
    left: 41%;
    top: 11%;
    transform: translateY(-11%, -41%);
  }
} */

.vertical-text {
  writing-mode: vertical-rl;
  transform: rotate(180);
}
.testHeaderTimeText {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
  color: black;
}
.openlink {
  font-family: "Inter" sans-serif;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  color: #0f7dff;
  text-decoration: underline;
  text-align: center;
  cursor: pointer;
  z-index: 777;
}
.openLinkModalWrapper {
  background: #d9d9d9;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 20px;
  border: 20px solid #d9d9d9;
  /* border-color: #d9d9d9; */
}

.box {
  background-color: #f7ff00;
  color: black;
  padding: 20px;
  margin: 20px;
  display: inline-block;
  vertical-align: middle;
}
.side_panel {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: 999;
  display: flex;
}
.side_Question {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: #f1f1f1;
  color: #000;
  border-top-left-radius: 35px;
  border-bottom-left-radius: 35px;
  width: 300px;
  height: 60vh;

  box-shadow: 0px 4px 4px 0px #00000040;
}
.control-button-in-exam-portal {
  width: 100%;
  /* text-align: right; */
}
.Question-single-body-holder {
  padding: 50px 0px;
  color: #fff;
}
.Single-question-body-image-container {
  width: 100%;
  height: 300px;
}
.Single-question-body-image-container-new {
  width: 100%;
  height: 300px;
  max-height: 175px;
  z-index: 9999999999 !important;
}
.Single-question-body-image {
  width: 40%;
  margin: 30px 30%;
}
.Question-single-option-panel {
  width: 100%;
}
.Single-option {
  min-height: 200px;
}

.Single-option-new {
  /* min-height: 200px; */
}
.option-image-in-exam-panel-holder {
  /* width: 100%; */
  width: 75%;
}
.option-image-in-exam-panel-holder-w0 {
  width: 0%;
}
.option-image-in-exam-panel {
  width: 60%;
  object-fit: contain;
  margin-left: 20%;
}
.End-test-container {
  width: 100%;
  text-align: center;
}

.radioQuestion .ant-radio-inner {
  background-color: #d9d9d9;
}
.radioQuestion .ant-radio-checked .ant-radio-inner:after {
  background-color: black;
  border-color: #000;
}

.radioQuestion .ant-radio:hover .ant-radio-inner {
  border-color: black;
  border-color: #000;
}
.radioQuestion .ant-radio:focus .ant-radio-inner:focus {
  border-color: black;
  border-color: #000;
}
.radioQuestion .ant-radio:active .ant-radio-inner:active {
  border-color: black;
  border-color: #000;
}

@media (max-device-width: 768px) {
  .Test-portal-not-started-yet-inner {
    width: 90%;
    min-height: 400px;
    margin-left: 5%;
  }
  .question-holder {
    width: 100%;
  }
}

@media (max-width: 1020px) {
  .questionNoText {
    font-family: "Inter" sans-serif !important;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
  }

  .questionBody {
    font-family: "Inter" sans-serif;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
  }
  .openlink {
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  .questionBody {
    font-size: 16px !important;
  }
}

@media (min-width: 1720px) {
  .questionNoText {
    font-size: 32px !important;
    font-family: "Inter" sans-serif !important;

    font-weight: 700;
  }
  .marksText {
    font-family: "Inter" sans-serif;
    font-size: 24px !important;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
  }
  .questionBody {
    font-family: "Inter" sans-serif;
    font-size: 48px !important;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
  }
  .clock {
    position: absolute;
    left: 50%;
    top: 11%;
    transform: translate(-50%, -11%);
  }
  .openlink {
    font-size: 36px;
  }
}

/* @media (min-height: 1200px) {
  .clock {
    position: absolute;
    left: 50%;
    top: 10.5%;
    transform: translate(-50%, -11%);
  }
}

@media (min-height: 2500px) {
  .clock {
    position: absolute;
    left: 50%;
    top: 10.3%;
    transform: translate(-50%, -11%);
  }
} */

.quickResultInfo {
  display: none;
  flex-direction: column;
  width: 500px;
  border-radius: 45px;
  background: #d9d9d9;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 30px;
}
.quickResultInfoText {
  padding: 20px 30px;
  color: #5e5e5e;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.quickResultHoverInfoBox:hover .quickResultInfo {
  display: flex;
}
.quickResultHoverInfoBox {
  position: relative;
}
