.conduct-test-main-wrapper {
  min-height: 80vh;
}
.test-conduct-testid-form {
  width: 30%;
  margin-left: 35%;
  padding: 50px;
  border-radius: 5px;
  background: linear-gradient(
    to right,
    rgb(183, 233, 247),
    rgb(219, 243, 250),
    rgb(245, 252, 255)
  );
  text-align: center;
}
.candidate-list-header-container {
  padding: 0px 0px;
}
.reload-button {
  margin-bottom: 20px;
}
.green {
  background: rgb(16, 143, 16);
  color: #fff;
}
