.profile_wrapper {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #3b4046 0%, #7a7a7a 100%) !important;
}

.profile_container {
  border: 2px solid #f1f1f1;
  width: 95%;
  margin: auto;
  height: 90vh;
  border-radius: 50px;
  overflow: auto;
}

.space-between {
  padding: 0% 1%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* .tabs {
  display: flex;
  justify-content: center;
  align-items: center;
} */
.container_inner {
  width: 95%;
  margin: auto;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.profile_text {
  font-family: "Inter", sans-serif;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 800;
  line-height: 48px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #f1f1f1;
}

.tabs .ant-tabs-bar {
  background-color: transparent;
}

.tabs .ant-tabs-nav {
  margin-bottom: 0; /* Remove the bottom margin of the tab navigation */
}
.tabs .ant-tabs-nav .ant-tabs-tab {
  margin: 0 !important;
  margin-left: -2px !important;
}
.tabs .ant-tabs-tab {
  color: #f1f1f1; /* Set the color of the tab text */
  padding: 10px 40px !important;

  border-radius: 0px 0px 25px 25px;
  position: relative; /* Set position relative to create a context for the divider */

  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
}

.tabs .ant-tabs-tab-active,
.tabs .ant-tabs-tab-active:focus {
  background-color: #f1f1f1;
  color: #4a525c !important;
  padding: 10px 40px !important;
}

.tabs
  .ant-tabs-tab:not(.ant-tabs-tab-active):not(
    .ant-tabs-tab-active:focus
  ):hover {
  color: #f1f1f1 !important;
}

/* padding: 10px 40px !important; */

.tabs
  .ant-tabs-tab:not(:last-child):not(.ant-tabs-tab-active):not(
    .ant-tabs-tab-active:focus
  )::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0px;
  height: 20%;
  width: 1px;
  background-color: #f1f1f1;
  transform: translateY(-50%);
}

.tabs .ant-tabs-nav {
  border-top: 3px solid #f1f1f1;
}

.ant-tabs-ink-bar {
  background-color: transparent;
}
.modalTitle {
  font-family: "Inter", sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: center;
}
.modalButtons {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.modalButtonYes {
  padding: 20px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-family: Inder; */
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  border: 1px solid #535353;
  cursor: pointer;
}
.modalButtonNo {
  padding: 20px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-family: Inder; */
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  background: #0082fb;
  color: white;
  cursor: pointer;
}
.ant-modal-content {
  border-radius: 20px;
}

@media (max-width: 600px) {
  .modalButtonNo {
    font-size: 12px;
  }
}

@media (min-width: 1700px) {
  .ant-tabs-tab {
    font-size: 30px;
  }
  .profile_text {
    font-size: 40px;
  }
}
@media (min-width: 769px) and (max-width: 1200px) {
  .ant-tabs-tab {
    font-size: 15px !important;
  }
  .profile_text {
    font-size: 22px !important;
  }
}

@media (min-width: 300px) and (max-width: 769px) {
  .ant-tabs-tab {
    font-size: 12px !important;
  }
  .profile_text {
    font-size: 18px !important;
  }
}
@media (max-width: 300px) {
  .profile_text {
    font-size: 14px !important;
  }
}

@media (max-width: 600px) {
  .tableDataText {
    font-size: 12px !important;
  }
}

.custom_trainee_table {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 30px 0px;
}
.sorting_Buttons {
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
}
.sortButton {
  width: 150px;
  background-color: #0082fb;
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: space-between;
}
.icons {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.showData {
  position: absolute;
  width: 100%;
  height: 200px;
  overflow-y: scroll;
  top: 0;
  color: white;
  left: 0;
  z-index: 20;
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: #938e8e;
  border-radius: 20px;
  padding: 12px;
}
.sortText {
  color: white;
}

.even_row {
  background-color: transparent !important;
  color: white !important;
  padding: 2px !important;
}

.odd_row {
  background-color: transparent !important;
  color: white !important;
  padding: 2px !important;
}

.even_row:hover,
.odd_row:hover {
  background-color: transparent !important;
}
.ant-table-tbody > tr.even_row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background-color: transparent !important;
}

.ant-table-tbody > tr.odd_row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background-color: transparent !important;
}

.custom_header {
  background-color: transparent !important;
  color: white !important;
}
.custom_tables .ant-table-thead > tr > th:last-child {
  border-bottom: none;
}
.custom_tables .ant-table-tbody > tr > td:last-child {
  border-bottom: none;
}

.custom_tables .ant-table-thead > tr > th {
  padding: 8px;
}
.custom_tables .ant-table-tbody > tr > td {
  padding: 8px;
}
.custom_tables .ant-table-placeholder {
  background-color: transparent;
}

@media (min-width: 0px) and (max-width: 769px) {
  .ant-table-wrapper {
    width: 100% !important;
  }
}

.custom_tables .ant-spin-container {
  position: relative;
  height: 100%;
}

.custom_tables .ant-table-pagination.ant-pagination {
  display: none;
}

@media (min-width: 1600px) {
  .resposiveFonts {
    font-family: "Inter" sans-serif;
    font-size: 20px !important;
    font-weight: 600;
    text-align: left;
  }
}

@media (max-width: 1020px) {
  .resposiveFonts {
    font-family: "Inter" sans-serif;
    font-size: 10px !important;
    font-weight: 500;
    text-align: left;
  }
}

.resposiveFonts {
  font-family: "Inter" sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}
